<template>
    <div class="introduce-wrapper">
        <navbar></navbar>
        <div  class="introduce-body">
            <div class="block" v-for="(item,index) in list" :key="index">
                <img :src="item.imgSrc" class="img"/>
                <div class="title">{{item.title}}</div>
                <div>{{item.content}}</div>
            </div>
            <record color="#999999"></record>
        </div>
    </div>
</template>

<script>
    import navbar from "./navbar";
    import record from "./record";
    export default {
        name: "introduce",
        components:{
            navbar,
            record
        },
        data(){
            return {
                list:[
                    {
                        imgSrc: require('../assets/pc/introduce/coupon.png'),
                        title: '超级神券',
                        content: '我们不生产优惠券，我们是优惠券的搬运工。优品淘金每日更新海量大额优惠券，全网百万优惠池一网打尽，让你天天都是双十一！解决你所有烦恼，真正让你省钱、省时、省心！'
                    },
                    {
                        imgSrc: require('../assets/pc/introduce/share.png'),
                        title: '分享获利',
                        content: '用户无论自购还是分享给他人，都可以获利，让你利益最大化。通过分享这些优惠链接，别人通过您分享的链接下单，就得到相应的报酬啦。'
                    },
                    {
                        imgSrc: require('../assets/pc/introduce/withdraw.png'),
                        title: '收益提现',
                        content: '除了购物，用户还可以通过完成每日任务、签到等方式轻松获得收益，收益更可以随时提现。别人网购吃土，自己网购省赚，不限空间时间，碎片时间赚个钱；真正为您实现省钱赚钱两不误。'
                    },
                    {
                        imgSrc: require('../assets/pc/introduce/platform.png'),
                        title: '多平台合作',
                        content: '目前除了已对接淘宝、京东、拼多多等各大平台外，还有美团饿了么点外卖、飞猪出门旅游订酒店买机票，衣食住行吃喝玩乐全部覆盖，真是全面实惠又贴心！'
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="scss">
    @mixin width-height($width: 100%, $height: 100%){
        width: $width;
        height: $height;
    }
    @mixin font($size: 16px,$color:#222222,$weight:400){
        font-size: $size;
        color: $color;
        font-weight: $weight;
    }
    @mixin flex($ai:center,$jc:space-between){
        display: flex;
        align-items: $ai;
        justify-content: $jc;
    }
    .introduce-wrapper{
        @include width-height;
        display: flex;
        flex-direction: column;

        .introduce-body{
            flex: 1;
            background-image: url('../assets/pc/introduce/bg.jpg');
            background-size:100% 100%;
            padding: 0 360px;
            @include flex;

            .block{
                @include width-height(280px,360px);
                box-sizing: border-box;
                background: #ffffff;
                border-radius: 20px;
                box-shadow: 0px 8px 27px 0px rgba(212,218,227,0.46);
                @include font;
                line-height: 28px;
                padding: 100px 20px 0;
                @include flex(center,flex-start);
                flex-direction: column;
                position: relative;

                .img{
                    @include width-height(280px,220px);
                    position: absolute;
                    top: -140px;
                }
                .title{
                    @include font(25px,#fa065a,600);
                    margin-bottom: 20px;
                }
            }
        }
    }
</style>
